export const nomenclaturaSituacaoAluno = {
  aprovado: "APROVADO",
  aprovadoAbreviacao: "AP",
  aprovadoPeloConselho: "APROVADO POR CONSELHO",
  aprovadoPeloConselhoAbreviacao: "APC",
  aprovadoCreche: 'CONCLUINTE',
  aprovadoCrecheAbreviacao: 'CON',
  reprovado: "REPROVADO",
  reprovadoAbreviacao: "REP",
  transferido: 'TRANSFERIDO',
  transferidoAbreviacao: 'TR',
  transferenciaExternaCreche: 'MATRÍCULA ENCERRADA',
  transferenciaExternaCrecheAbreviacao: 'ME',
  evadido: 'EVADIDO',
  evadidoAbreviacao: 'EV',
  falecido: 'FALECIDO',
  falecidoAbreviacao: 'FAL',
};
