import axios from 'axios'

export class Atas {
  static async buscar() {
    return axios.get('/atas');
  }

  static async show(id) {
    return axios.get('/atas/show/'+ id);
  }

  static async store(payload) {
    return axios.post('/atas', payload)
  }

  static async update(payload) {
    return axios.put('/atas/'+ payload.id, payload);
  }


  static async buscarAlunosParaAta(info) {
    return axios.post('/atas/alunos/reprovados/'+ info.ata_id+'/'+info.turma_id);
  }

  static async adicionarInfoNovaParaAluno(payload) {
    return axios.post('/atas/alunos/alterar/info', payload);
  }

  static async excluir(id) {
    return axios.delete('atas/' + id);
  }

  static async buscarDados (n) {
    return axios.get('/atas?ano='+n.ano+'&numero='+n.numero+'&turma_nome='+n.turma_nome+'&segmento_nome='+n.segmento_nome+'&serie_nome='+n.serie_nome+'&escola_nome='+n.escola_nome+'')
  }
}
